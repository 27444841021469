import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, FileUpload, Select, TextInput } from 'components/Form'
import { createApiRecord, updateApiRecord } from 'services/ProductService'
import { useDispatch, useSelector } from 'react-redux'
import { getRecord } from './store/dataSlice'
import reducer from './store'
import { injectReducer } from 'store'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { yesNoOptions } from 'utils/options'
import { imageUploadApi } from 'services/ImageService'
injectReducer('product', reducer)

const validationSchema = Yup.object().shape({
    company: Yup.string().required('Please Select Company'),
    name: Yup.string().required('product name is required'),
    category: Yup.string().required('Please Select Category'),
    sub_category: Yup.string().required('Please Select Sub Category'),
    warranty_period: Yup.number().required('warranty period is required'),
    extended_warranty_period: Yup.number().nullable().optional(),
    description: Yup.string().nullable().optional(),
    model_number: Yup.string().required('Model number is required'),
    is_component: Yup.boolean().required('this field is required'),
    assembled: Yup.boolean().required('this field is required'),
})

const Create = props => {
    const { initialData } = props
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { companyData, categoryData } = useSelector(state => state.listData)
    const { record, loading, tableData } = useSelector(state => state.product.data)

    useEffect(() => {
        if (id) {
            fetchData(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchData = id => dispatch(getRecord(id))

    const handleDiscard = () => navigate('/manage-product/products')

    const handleSubmit = async datas => {
        const data = { ...datas }

        try {
            if (Array.isArray(data.image)) {
                for (let item of data.image) {
                    const imageData = await imageUploadApi({ image: item })
                    data.image = imageData.data?.image ?? ''
                }
            }

            if (!data.image) {
                delete data.image
            }

            if (id) {
                if (!data?.batch_number) {
                    delete data?.batch_number
                }

                updateApiRecord(id, data)
                    .then(() => {
                        Success('Updated Successfully!')
                        handleDiscard()
                    })
                    .catch(error => console.log('Error: ', error))
            } else {
                createApiRecord(data)
                    .then(() => {
                        Success('Created Successfully!')
                        handleDiscard()
                    })
                    .catch(error => console.log('Error: ', error))
            }
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    if (loading && id) {
        return null
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Products</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">{id ? 'Update Product' : 'Add New Product'}</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-6">
                        <Formik
                            initialValues={
                                id
                                    ? { ...record }
                                    : { ...initialData, company: tableData?.company ? tableData?.company : companyData[0]?.id }
                            }
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                handleSubmit(values)
                            }}
                        >
                            {({ values, touched, errors }) => (
                                <Form>
                                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                                        <Field name="company">
                                            {({ field, form }) => (
                                                <Select
                                                    options={companyData.filter(item => !item?.is_temporary)
                                                }
                                                    label="Company"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="company"
                                                    error={errors.company && touched.company}
                                                    message={errors.company}
                                                    value={values?.company}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Product Name"
                                                    name="name"
                                                    error={errors.name && touched.name}
                                                    message={errors.name}
                                                    value={values?.name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="category">
                                            {({ field, form }) => (
                                                <Select
                                                    options={categoryData.filter(
                                                        item => !item.is_subcategory && !item?.is_deleted
                                                    )}
                                                    label="Category *"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="category"
                                                    error={errors.category && touched.category}
                                                    message={errors.category}
                                                    value={values?.category}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="sub_category">
                                            {({ field, form }) => (
                                                <Select
                                                    options={categoryData.filter(
                                                        item => item.is_subcategory && !item?.is_deleted
                                                    )}
                                                    label="Sub Category *"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="sub_category"
                                                    error={errors.sub_category && touched.sub_category}
                                                    message={errors.sub_category}
                                                    value={values?.sub_category}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="warranty_period">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Warranty Period (in months)"
                                                    name="warranty_period"
                                                    error={errors.warranty_period && touched.warranty_period}
                                                    message={errors.warranty_period}
                                                    value={values?.warranty_period}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="extended_warranty_period">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Extended Warranty Period (in months)"
                                                    name="extended_warranty_period"
                                                    error={errors.extended_warranty_period && touched.extended_warranty_period}
                                                    message={errors.extended_warranty_period}
                                                    value={values?.extended_warranty_period}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="is_component">
                                            {({ field, form }) => (
                                                <Select
                                                    options={yesNoOptions}
                                                    label="Is Component"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="is_component"
                                                    error={errors.is_component && touched.is_component}
                                                    message={errors.is_component}
                                                    value={values?.is_component}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="assembled">
                                            {({ field, form }) => (
                                                <Select
                                                    options={yesNoOptions}
                                                    label="Is Assembled"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="assembled"
                                                    error={errors.assembled && touched.assembled}
                                                    message={errors.assembled}
                                                    value={values?.assembled}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="model_number">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Model Number *"
                                                    name="model_number"
                                                    error={errors.model_number && touched.model_number}
                                                    message={errors.model_number}
                                                    value={values?.model_number}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="description">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Description"
                                                    name="description"
                                                    error={errors.description && touched.description}
                                                    message={errors.description}
                                                    value={values?.description}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="image">
                                            {({ field, form }) => (
                                                <FileUpload
                                                    label="Image"
                                                    className="md:col-span-2"
                                                    name="image"
                                                    onChange={e => {
                                                        form.setFieldValue(field.name, [e.target.files[0]])
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="flex items-center justify-end gap-4 mt-3">
                                        <Button
                                            className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                            onClick={handleDiscard}
                                        >
                                            Discard
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

Create.defaultProps = {
    initialData: {
        company: '',
        name: '',
        category: '',
        sub_category: '',
        warranty_period: '',
        extended_warranty_period: 0,
        description: '',
        model_number: '',
        is_component: '',
        assembled: '',
        image: '',
    },
}

export default Create
